.login{
    padding: 41px 52px 0 52px;
}

.login .title{
    width: 100%;
    text-align: center;
    margin: 10px auto;
}

.login .item{
    display: flex;
    flex-direction: row;
    padding: 13px 0;
    font-size: 19px;
    color: #303133;
    box-sizing: border-box;
    line-height: 47px;
    flex-direction: column;
    border-bottom: 1px solid #e4e7ed;
}

.login .item input{
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
    outline: none;
    border: none;
    padding: 0;
}
.login .btn{
    margin-top: 54px;
}

.login .btn button{
    font-size: 20px;
    height: 54px;
    line-height: 54px;
    border-radius: 68px;
    background-color: rgb(55, 68, 134);
    color: rgb(255, 255, 255);
    cursor: pointer;
    padding: 0 27px;
    z-index: 1;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.login .tips{
    color: #909399;
    padding: 13px;
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.LRlink{
    display: block;
    width: 100%;
    text-align: center;
}

.login .avatar{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}