body {
    background-color: #f4f6f8;
    color: #303133;
    font-size: 14px;
}
.top {
    z-index: 10907;
    background: rgb(55, 68, 134);
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: normal;
    line-height: 44px;
    text-align: center;
    height: 44px;
}
.coupon_tabs {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #fff;
}

.coupon_tabs li {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 0px 16px;
    flex: 1 1 auto;
    width: 96px;
    color: rgb(48, 49, 51);
    position: relative;
    display: inline-block;
    text-align: center;
}

.coupon_tabs .active {
    color: rgb(55, 68, 134);
    font-weight: bold;
    border-bottom: 3px solid rgb(55, 68, 134);
}

.coupon_list{
    padding: 16px;
}

.coupon_list .coupon_item{
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.coupon_list .coupon_item .item_content .a{
    padding: 16px;
    border-right: 1px dashed #ddd;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #303133;
}

.coupon_list .coupon_item .item_content .left {
    width: 64px;
    font-size: 21px;
    font-weight: 700;
    color: #e74c3c;
}

.coupon_list .coupon_item .item_content .left  span {
    font-size:12px;
}

.coupon_list .coupon_item .item_content .right{
    max-width: 176px;
    width: 176px;
}

.coupon_list .coupon_item .item_content .right div{
    margin-bottom: 2px;
    font-size: 16px;
}

.coupon_list .coupon_item .item_content .right div:nth-child(2){
    color: #909399;
    font-size: 14px;
}

.coupon_list .coupon_item .item_content .right div:nth-child(2) span{
    color: rgb(55, 68, 134);
}

.coupon_list .coupon_item .item_content .right div:nth-child(3), .coupon_list .coupon_item .item_content .right div:nth-child(4){
    color: #909399;
    font-size: 12px;
}

.coupon_list .coupon_item .item_btn{
    font-size: 14px;
    display: flex;
    justify-content:center;
    align-items: center;
    border: 1px solid #f7f7f7;
    width: 100%;
}

.coupon_list .coupon_item .item_btn .right_btn1{
    color: rgb(7, 3, 231);
    background-color: #f7f7f7;
    display: inline-flex;
    width: auto;
    font-size: 11px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid rgb(7, 3, 231);
}
.coupon_list .coupon_item .item_btn .right_btn2{
    color: rgba(0,0,0,.3);
    background-color: #f7f7f7;
    display: inline-flex;
    width: auto;
    font-size: 11px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.3);
}